'use client';

import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/navigation';
import Button from '@/components/ui/Button';
import styles from './styles.module.scss';

export interface IBreadcrumbProps {
  items?: {
    label: string;
    href?: string;
  }[];
  hideBack?: boolean;
}

const Breadcrumb: React.FunctionComponent<IBreadcrumbProps> = (props) => {
  const router = useRouter();
  const { t } = useTranslation();

  if (!props.items || props.items.length < 2) {
    return null;
  }

  return (
    <React.Fragment>
      <ul className={styles['breadcrumb']}>
        {props.items.map((item) => (
          <li key={item.label}>
            {item.href ? (
              <Link
                href={item.href}
                draggable={false}
                prefetch={false}
              >
                {item.label}
              </Link>
            ) : (
              <span>{item.label}</span>
            )}
          </li>
        ))}
      </ul>
      {!props.hideBack && (
        <div className={styles['breadcrumb__back']}>
          <Button
            title={t('generic.actions.goBack')}
            iconLeft="arrow03-left"
            onClick={(e) => {
              e.preventDefault();
              router.back();
            }}
            theme="link"
          />
        </div>
      )}
    </React.Fragment>
  );
};

Breadcrumb.displayName = 'Breadcrumb';

export default Breadcrumb;
